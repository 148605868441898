
















import { Component, Prop, Vue } from 'vue-property-decorator'
import moment  from 'moment';
import { api } from '../../lib/api'
import { IBatch, IOrderItem, defaultBatch} from '../../models/schemas' 
import { $$t } from '../../lib/i18n'

@Component
export default class LvlBatchDetail extends Vue {  
 

    @Prop({default:false})
    striped:boolean|undefined

    @Prop({default:defaultBatch()})
    batch:IBatch|undefined

    private items:Array<IOrderItem> = []

    mounted() {
      this.getItems()     
    }

    public getItems():Promise<void>{ 
      if(!this.batch || !this.batch.id)
        return Promise.resolve()
      return api.call("admin/admin-batchs/details", {id:this.batch.id}).then(res => {
        const batchDetail:IBatch = res.getItem() as IBatch
        this.items = batchDetail.orderItems as IOrderItem[]  
      }) 
    }

    public fields:any[] = this.getFields()

    private getFields():any{
      const ret = []
      ret.push({key:'client', label: $$t('client'), formatter:(v:any, key:string, item:IOrderItem)=>item.order.client.name, sortable:true, sortByFormatted:true })
      ret.push({key:'orderid', label: $$t('order_order_id'), formatter:(v:any, key:string, item:IOrderItem)=>item.order.orderid, sortable:true, sortByFormatted:true })
      ret.push({key:'date', label: $$t('date'), formatter:(v:any, key:string, item:IOrderItem)=>this.formatDate(item.order.date), sortable:true, sortByFormatted:true })
      ret.push({key:'quantity', label: $$t('order_row_quantity'), sortable:true })
      ret.push({key:'status', label: $$t('order_status'), formatter:(v:any, key:string, item:IOrderItem)=>$$t("order_status_"+item.order.status.toLowerCase()), sortable:true, sortByFormatted:true })
      return ret
    }  

    private formatDate(value:string):string{
      const m = moment(value,'YYYY-MM-DD"T"HH:mm:ss.SZ')
      return m.format("YYYY-MM-DD") 
    }
}
