








































import { Component, Vue } from 'vue-property-decorator'
import LvlOrderAdmin from '../../components/admin/lvl-order-admin.vue'
import LvlOrderAdminNav from '../../components/admin/lvl-order-admin-nav.vue'

@Component({
   components:{LvlOrderAdmin, LvlOrderAdminNav}
})
export default class AdminOrdersShippingPage extends Vue {

    private get lvlOrderAdmin():LvlOrderAdmin{ return this.$refs.lvlOrderAdmin && (this.$refs.lvlOrderAdmin as LvlOrderAdmin)}

    private anyRowSelected:boolean = false

    orderSelected()
    {
      this.anyRowSelected = this.lvlOrderAdmin.anyRowSelected
    }

    changeSelectedOrdersStatus(targetStatus:string):Promise<void>
    {
      return this.lvlOrderAdmin.changeSelectedOrdersStatus(targetStatus)
    }
}

