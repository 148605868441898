























































































































import { Component, Vue } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import moment  from 'moment';
import { api } from '../../lib/api'
import { IOrder, IClient} from '../../models/schemas' 
import { $$t } from '../../lib/i18n' 
import LvlOrderDetail from '../../components/lvl-order-detail.vue'
import { routes } from '../../lib/routes';


@Component({
   components:{LvlOrderDetail}
})
export default class AdminOrdersPage extends Vue {

    private ordersFields:any[] = [
      {key:'orderid', label:'ID', sortable:true},
      {key:'client', label: $$t('admin_client'),  formatter:(v:any, key:string, item:IOrder)=>item.client.name, sortable:true, sortByFormatted:true},
      {key:'shippingArea', label: $$t('admin_shipping_area'),  formatter:(v:any, key:string, item:IOrder)=>item.client.shippingArea, sortable:true, sortByFormatted:true},
      {key:'date', label: $$t('date'), sortable:true, formatter : this.formatDate},
      {key:'status', label: $$t('order_status'), sortable:true, formatter : this.formatStatus, sortByFormatted:true},
      {key:'price', label: $$t('order_price')+" ("+$$t('unit_currency')+")", sortable:true},
      {key:'isArchived', label:$$t('admin_isarchived'), sortable:true},
      {key:'actions', label:''}
    ]

    private statuses:any[] = [
      {text:$$t("order_status_reception"), value:"RECEPTION"},
      {text:$$t("order_status_production"), value:"PRODUCTION"},
      {text:$$t("order_status_shipping"), value:"SHIPPING"},
      {text:$$t("order_status_delivered"), value:"DELIVERED"},
      {text:$$t("order_status_deliverfailed"), value:"DELIVERFAILED"},
      {text:$$t("order_status_cancelled"), value:"CANCELLED"},
    ]

    private orders:Array<IOrder> = []
    private clients:Array<IClient> = []
    private shippingAreas:Array<any> = []

    private displayClient:string = ""
    private displayArea:string = ""
    private displayStatus:string = ""
    private displayArchived:string = "no"

    private busy:boolean = false

    get routes(){ return routes }

    mounted() {
      Promise.all([
        this.getClients(),
        this.getShippingAreas()
      ])
      .then(()=> this.getItems())
    }

    private getItems():Promise<void> {  
      this.busy = true  

      return api.call("admin/admin-orders/list", {client:this.displayClient, shippingArea: this.displayArea, status: this.displayStatus, isArchived:this.displayArchived }).then(res => {
        this.busy = false
        this.orders = res.getItems() as IOrder[]
      })   
    }

    private getClients():Promise<void> {  
      return api.call("admin/admin-orders/clients").then(res => {
        this.clients = res.getItems() as IClient[]
      })   
    }

    private getShippingAreas():Promise<void> {  
      return api.call("admin/admin-orders/shippingAreas").then(res => {
        this.shippingAreas = res.data
      })   
    }

    private formatShippingArea(code:string):string{
      if(this.shippingAreas)
      {
        var area = this.shippingAreas.find(a=>a.value ==code)
        if(area)
          return area.label
      }
      return code
    }

    private formatDate(value:string):string{
      const m = moment(value,'YYYY-MM-DD"T"HH:mm:ss.SZ')
      return m.format("YYYY-MM-DD") //m.format("LL") + " ("+m.fromNow()+")"
    }

    private formatStatus(value:string):string{
      return $$t('order_status_'+value.toLowerCase())
    }

    private manageOrder(order:IOrder)
    {
      var route = (routes as any)["adminOrders_"+order.status.toLowerCase()]
      if(route)
      {
        this.$router.push({path:route.path, query:{order:order.id}})
      }
      
    }

    private archive():Promise<void>
    {
        var ids = this.selectedItems().map(o=>o.id)  
        return api.call("admin/admin-orders/archive", {ids:ids}).then(() => {
            this.getItems()
        }) 
    }     

    private unarchive():Promise<void>
    {
        var ids = this.selectedItems().map(o=>o.id)  
        return api.call("admin/admin-orders/unarchive", {ids:ids}).then(() => {
            this.getItems()
        }) 
    }  

    private nbRowsSelected:number = 0
    private get allRowsSelected():boolean { return this.nbRowsSelected > 0 && this.nbRowsSelected == (this.orders && this.orders.length) }
    private set allRowsSelected(v:boolean){}
    private get anyRowSelected():boolean { return this.nbRowsSelected > 0}
    private get table():BTable { return (this.$refs.tblItems as BTable)}

    private select(selected:IOrder[]){
      this.nbRowsSelected = selected.length
    }

    private toggleSelectRow(row:any){
      if(!row.rowSelected)
        row.unselectRow()
      else    
        row.selectRow()  
    }

    private toggleSelectAllRows(headRow:any){
      if(this.allRowsSelected)
        headRow.clearSelected()
      else
        headRow.selectAllRows()
    }

    private selectedItems():IOrder[]
    {
      return this.table.selectedRows.map((sel:boolean,i:number)=> sel ? this.table.sortedItems[i] : null).filter((s:IOrder)=>s)
    }   

}

