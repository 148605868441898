





















































import { Component, Vue } from 'vue-property-decorator'

import { routes } from '../../lib/routes'
import { authentication } from '../../lib/authentication' 
import LvlOrderAdminNav from '../../components/admin/lvl-order-admin-nav.vue'

@Component({
   components:{LvlOrderAdminNav}
})
export default class AdminPage extends Vue {

    get routes(){ return routes }
    get authentication(){ return authentication }   

}

