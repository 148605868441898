











































































































































































import { Component, Vue } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import { api } from '../../lib/api'
import { validation } from '../../lib/validation'
import { IClient, defaultClient} from '../../models/schemas' 
import { $$t, $$error } from '../../lib/i18n' 

@Component
export default class AdminClientsPage extends Vue {

    private fields:any[] = [
      {key:'name', label:$$t('admin_name'), sortable:true},
      {key:'shippingArea', label: $$t('admin_shipping_area'), sortable:true, formatter:this.formatShippingArea},
      {key:'users', label: $$t('admin_users'), sortable:true},
      {key:'isActive', label: $$t('admin_isactive'), sortable:true}
    ]

    private validation = validation()

    private shippingAreas:Array<any> = []
    private items:Array<IClient> = []

    private displayActive:string = ""
    private displayArea:string = ""
    private busy:boolean = false
    private formOpened:boolean = false

    private selectedItem:IClient = defaultClient()

    private selectedItemRemovable():boolean {
      return this.selectedItem && this.selectedItem.id!="" && !this.selectedItem.isActive && (!this.selectedItem.users || this.selectedItem.users.length == 0)
    }

    private selectedItemIsNew():boolean {
      return this.selectedItem && this.selectedItem.id==""
    }

    private get itemsTable():BTable { return (this.$refs.tblItems as BTable)}

    mounted() {
      Promise.all([
        this.getShippingAreas()
      ])
      .then(()=>this.getItems())
      
    }


    private getShippingAreas():Promise<void> {  
      return api.call("admin/admin-clients/shippingAreas").then(res => {
        this.shippingAreas = res.data
      })   
    }

    private getItems():Promise<void> {  
      this.busy = true  

      return api.call("admin/admin-clients/list", {isActive:this.displayActive, shippingArea: this.displayArea }).then(res => {
        this.busy = false
        this.items = res.getItems() as IClient[]
      })   
    }

    private select(item:IClient)
    {
      this.validation.reset()
      this.selectedItem = item
      this.formOpened = true
    }

    private createNew()
    {
      const created = defaultClient();
      if(this.displayArea)
        created.shippingArea = this.displayArea      
      this.select(created)
    }
    
    private closeEditionModal()
    {
      this.validation.reset()
      this.itemsTable.clearSelected()
      this.formOpened = false
    }

    private save()
    {
      if(this.validation.ko)
        return;
      this.busy = true  

      api.call("admin/admin-clients/update", {item:this.selectedItem }).then(ret => {
        ret.throwErrors()
        this.closeEditionModal();
        return this.getItems();
      }) 
      .catch(err => this.showError(err))     
    }

    private cancel()
    {
      this.getItems();
      this.closeEditionModal();   
    }

    private remove()
    {
      this.$bvModal.msgBoxConfirm(
        $$t("admin_confirm_delete") + " : \""+this.selectedItem.name+"\" ?", 
        {title: $$t("admin_delete"), headerBgVariant:"danger", headerTextVariant:"light", centered:true}
        )
      .then(ok=>{
        if(ok){
          this.busy = true  

          api.call("admin/admin-clients/remove", {id:this.selectedItem.id }).then(ret => {
            ret.throwErrors()
            this.closeEditionModal();
            return this.getItems();
          })           
          .catch(err => this.showError(err))  
        }
      })
    }
    
    private showError(err:any){
        this.$bvToast.toast($$error(err), {
          title: $$t('error'),
          toaster: 'b-toaster-bottom-full',
          noAutoHide: true,
          variant:'danger'
        })
    }

    private formatShippingArea(code:string):string{
      if(this.shippingAreas)
      {
        var area = this.shippingAreas.find(a=>a.value ==code)
        if(area)
          return area.label
      }
      return code
    }

    private useShippingAddessAsBillingAddressChanged(v:boolean){
      if(v)
      {
        var item = this.selectedItem
        item.billingAddress_name = item.shippingAddress_name
        item.billingAddress_details = item.shippingAddress_details
        item.billingAddress_street = item.shippingAddress_street
        item.billingAddress_postcode = item.shippingAddress_postcode
        item.billingAddress_city = item.shippingAddress_city
        item.billingAddress_country = item.shippingAddress_country
      }
    }
}

