


















































































































































import { Component, Vue } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import { api } from '../../lib/api'
import { validation } from '../../lib/validation'
import { IUser, defaultUser, IClient, defaultClient} from '../../models/schemas' 
import { $$t,$$error } from '../../lib/i18n' 

@Component
export default class AdminUsersPage extends Vue {

    private fields:any[] = [
      {key:'name', label:$$t('admin_name'), sortable:true},
      {key:'email', label: $$t('email'), sortable:true},
      {key:'isActive', label: $$t('admin_isactive'), sortable:true},
      {key:'profile', label:$$t('admin_profile'), sortable:true, formatter:this.formatProfile},
      {key:'client.name', label:$$t('admin_client'), sortable:true}
    ]

    private validation = validation()

    private profiles:Array<any> = []
    private locales:Array<any> = []
    private clients:Array<IClient> = []
    private items:Array<IUser> = []

    private displayActive:string = ""
    private displayClient:string = ""
    private displayProfile:string = ""
    private busy:boolean = false
    private formOpened:boolean = false

    private selectedItem:IUser = defaultUser()

    private selectedItemRemovable():boolean {
      return this.selectedItem && this.selectedItem.id!="" && !this.selectedItem.isActive 
    }

    private selectedItemIsClient():boolean {
      return this.selectedItem && this.selectedItem.profile=="CLIENT" 
    }

    private selectedItemIsNew():boolean {
      return this.selectedItem && this.selectedItem.id==""
    }

    private get itemsTable():BTable { return (this.$refs.tblItems as BTable)}

    mounted() {
      Promise.all([
        this.getProfiles(),
        this.getClients(),
        this.getLocales()
      ])
      .then(()=>this.getItems())
    }

    private getProfiles():Promise<void> {  
      return api.call("admin/admin-users/profiles").then(res => {
        this.profiles = res.data.map((p:any)=> ({label:this.formatProfile(p), value:p}) )
      })
    }

    private getClients():Promise<void> {  
      return api.call("admin/admin-users/clients").then(res => {
        this.clients = res.getItems() as IClient[]       
      })   
    }

    private getLocales():Promise<void> {  
      return api.call("admin/admin-users/locales").then(res => {
        this.locales = res.data.map((p:string)=> ({label:$$t("locale_"+p.toLowerCase()), locale:p}))
      })   
    }

    private getItems():Promise<void> {  
      this.busy = true  

      return api.call("admin/admin-users/list", {isActive:this.displayActive, client: this.displayClient, profile:this.displayProfile }).then(res => {
        this.busy = false
        this.items = res.getItems() as IUser[]
      })   
    }

    private select(item:IUser)
    {
      this.validation.reset()
      if(!item.client)
        item.client = defaultClient()
      this.selectedItem = item
      this.formOpened = true
    }

    private createNew()
    {
      const created = defaultUser();
      if(this.displayClient)
        created.client = this.clients.find(c=> c.id== this.displayClient)
      if(this.displayProfile)
        created.profile = this.displayProfile
      this.select(created)
    }
    
    private closeEditionModal()
    {
      this.validation.reset()
      this.itemsTable.clearSelected()
      this.formOpened = false
    }

    private save()
    {
      if(this.validation.ko)
        return;
      this.busy = true  

      api.call("admin/admin-users/update", {item:this.selectedItem }).then(ret => {
        ret.throwErrors()
        this.closeEditionModal();
        return this.getItems();
      }) 
      .catch(err => this.showError(err))     
    }

    private cancel()
    {
      this.getItems();
      this.closeEditionModal();   
    }

    private remove()
    {
      this.$bvModal.msgBoxConfirm(
        $$t("admin_confirm_delete") + " : \""+this.selectedItem.name+"\" ?", 
        {title: $$t("admin_delete"), headerBgVariant:"danger", headerTextVariant:"light", centered:true}
        )
      .then(ok=>{
        if(ok){
          this.busy = true  

          api.call("admin/admin-users/remove", {id:this.selectedItem.id }).then(ret => {
            ret.throwErrors()
            this.closeEditionModal();
            return this.getItems();
          })           
          .catch(err => this.showError(err))  
        }
      })
    }
    
    private showError(err:any){
        this.$bvToast.toast($$error(err), {
          title: $$t('error'),
          toaster: 'b-toaster-bottom-full',
          noAutoHide: true,
          variant:'danger'
        })
    }

    private formatProfile(profile:String):String{
      return $$t("admin_profile_"+profile.toLowerCase())
    }
}

