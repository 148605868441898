


































import { Component, Prop, Vue } from 'vue-property-decorator'

import { routes } from '../../lib/routes'

@Component
export default class LvlOrderAdminNav extends Vue {

    get routes(){ return routes }

    @Prop({default:""})
    public activeStatus:String|undefined
    
    @Prop({default:"md"})
    public size:string|undefined

    private getClass(status:String):String{
        if(status == this.activeStatus)
            return "dark"
        else if(status =="DELIVERFAILED")
            return "warning"
        else if(status =="CANCELLED")
            return "danger"
        else
            return "info"
    }
}

