









































































































































import { Component, Vue } from 'vue-property-decorator'
import { api } from '../../lib/api'
import { basket } from '../../lib/basket'
import { IOrder, IClient, IProduct, IOrderItem, defaultOrder, defaultOrderItem, defaultProduct} from '../../models/schemas' 
import { $$t } from '../../lib/i18n' 
import { validation } from '../../lib/validation'
import LvlOrderDetail from '../../components/lvl-order-detail.vue'


@Component({
   components:{LvlOrderDetail}
})
export default class AdminCreateOrderPage extends Vue {


    private validation = validation()
    private item:IOrder = defaultOrder()
    private get orderItems():IOrderItem[] { return this.item.orderitems}
    private clients:Array<IClient> = []
    private products:Array<IProduct> = []


    private orderConfirmedPending:boolean = false
    private orderConfirmedSuccess:boolean = false
    private orderConfirmedOrderId:string=""

    private busy:boolean = false

    mounted() {
      Promise.all([
        this.getClients(),
        this.getProducts()
      ]).then(()=>{
        this.reinit()
      })
    }

    private newOrder():IOrder{
      const order = defaultOrder()
      order.date = new Date().toISOString()
      order.orderitems = [this.createOrderItem()]
      return order;
    }

    private createOrderItem():IOrderItem{
      const newOrderItem = defaultOrderItem()
      newOrderItem.product = defaultProduct()
      Object.assign(newOrderItem.product, this.products[0])
      newOrderItem.quantity = 1
      return newOrderItem
    }

    private addOrderItem():void{
      this.item.orderitems.push(this.createOrderItem())
      this.refreshItems()
    }

    private delOrderItem(orderItem:IOrderItem):void{
      this.item.orderitems = this.item.orderitems.filter(oi=> oi!=orderItem)
      this.refreshItems()
    }

    private getClients():Promise<void> {  
      return api.call("admin/admin-orders/clients").then(res => {
        this.clients = res.getItems() as IClient[]
      })   
    }

    private getProducts():Promise<void> {  
      return api.call("admin/admin-orders/products").then(res => {
        this.products = res.getItems() as IProduct[]
      })   
    }

    public orderItemsFields:any[] = [
      {key:'product', label: $$t('product') },
      {key:'quantity', label: $$t('order_row_quantity') },
      {key:'actions', label: "" }
    ]

    private refreshItems(){
      this.item.orderitems.forEach(oi=>{
        if(oi.product && oi.product.id && !oi.product.label)
        {
          Object.assign(oi.product, this.products.find(p=>p.id == oi.product.id) as IProduct)
        }
      })
      basket.refreshItems(this.item)
    }

    private reinit(){
      this.item = this.newOrder()
      this.refreshItems()
    }

    private save(){

      if(this.validation.ko)
        return;

      this.checkOrder(this.item).then(order => {
        this.item = order
        this.openOrderModal()
      })         
    }

    private cancel(){
      this.reinit()
    }

    private checkOrder(order:IOrder):Promise<IOrder>{
       return api.call("admin/admin-orders/checkOrder", {order:order}).then(res => {
        return res.getItem() as IOrder
      })     
    }

    private createOrder(order:IOrder):Promise<IOrder>{
       return api.call("admin/admin-orders/createOrder", {order:order}).then(res => {
        return res.getItem() as IOrder
      })     
    }

    private openOrderModal():void
    {
      this.$bvModal.show("order-modal")       
    }

    private closeOrderModal():void
    {
      this.$bvModal.hide("order-modal")
    }

    private confirmOrder():void
    {
      this.orderConfirmedPending = true
      this.$bvModal.hide("order-modal")
      this.$bvModal.show("order-confirmed-modal")   

      this.createOrder(this.item)
         .then(order=>{
          this.orderConfirmedSuccess = true
          this.orderConfirmedOrderId = order?.orderid || ""
          this.orderConfirmedPending = false
        })  
        .catch(err=>{
          console.log(err)
          this.orderConfirmedSuccess = false
          this.orderConfirmedPending = false
        })         
    }

    private closeOrderConfirmedModal():void
    {
      this.$bvModal.hide("order-confirmed-modal")
      this.reinit()
    }    
}

