



















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import moment  from 'moment';
import { api } from '../../lib/api'
import { validation } from '../../lib/validation'
import { IBatch, IProduct, defaultBatch} from '../../models/schemas' 
import { $$t, $$error } from '../../lib/i18n' 
import LvlBatchDetail from '../../components/admin/lvl-batch-detail.vue'

@Component({
   components:{LvlBatchDetail}
})
export default class AdminBatchsPage extends Vue {

    
    private fields:any[] = [
      {key:'productName', label:$$t('product'), formatter:(v:any, key:string, item:IBatch)=>item.product.label, sortable:true, sortByFormatted:true},
      {key:'productionDate', label:$$t('admin_productiondate'), sortable:true, formatter : this.formatDate},
      {key:'expirationDate', label:$$t('admin_expirationdate'), sortable:true, formatter : this.formatDate, tdClass:(v:string)=> v<=new Date().toISOString() ? "text-danger":"text-success"},
      {key:'productionQuantity', label: $$t('admin_productionquantity'), sortable:true},
      {key:'remainingQuantity', label: $$t('admin_remainingquantity'), sortable:true, tdClass:(v:number)=> v<=0 ? "text-danger":"text-success"},
      {key:'isArchived', label:$$t('admin_isarchived'), sortable:true},
      {key:'actions', label:''}
    ]

    private validation = validation()
    private items:Array<IBatch> = []
    private products:Array<IProduct> = []
    private displayProduct:string = ""
    private displayArchived:string = "no"
    private busy:boolean = false
    private formOpened:boolean = false

    private selectedItem:IBatch = defaultBatch()

    private selectedItemRemovable():boolean {
      return this.selectedItem && this.selectedItem.id!="" && this.selectedItem.isArchived == true
    }

    private get selectedItemIsNew():boolean {
      return this.selectedItem && !this.selectedItem.id
    }
    
    private get selectedItemIsLabel():string {
      return this.selectedItem && this.selectedItem.product && this.selectedItem.product.label
    }

    private uploadedFile:File|undefined

    private get itemsTable():BTable { return (this.$refs.tblItems as BTable)}

    mounted() {
      Promise.all([
        this.getProducts(),
      ])
      .then(()=> this.getItems())      
    }

    private getItems():Promise<void> {  
      this.busy = true  

      return api.call("admin/admin-batchs/list", {product:this.displayProduct, isArchived:this.displayArchived }).then(res => {
        this.busy = false
        this.items = res.getItems() as IBatch[]
      })   
    }


    private getProducts():Promise<void> {  
      return api.call("admin/admin-batchs/products").then(res => {
        this.products = res.getItems() as IProduct[]
      })   
    }    

    private open(item:IBatch)
    {
      this.validation.reset()
      this.selectedItem = item
      this.formOpened = true
    }

    private createNew()
    {
      const created = defaultBatch();
      if(this.displayProduct && created.product)
        created.product.id = this.displayProduct
      this.open(created)   
    }
    
    private closeEditionModal()
    {
      this.validation.reset()
      this.itemsTable.clearSelected()
      this.formOpened = false
    }

    private save()
    {
      if(this.validation.ko)
        return;
        
      this.busy = true  

      api.call("admin/admin-batchs/update", {item:this.selectedItem }).then(ret => {
        ret.throwErrors()
        this.closeEditionModal();
        return this.getItems();
      }) 
      .catch(err => this.showError(err))     
    }

    private cancel()
    {
      this.getItems();
      this.closeEditionModal();   
    }

    private remove()
    {
      this.$bvModal.msgBoxConfirm(
        $$t("admin_confirm_delete") +" ?", 
        {title: $$t("admin_delete"), headerBgVariant:"danger", headerTextVariant:"light", centered:true}
        )
      .then(ok=>{
        if(ok){
          this.busy = true  

          api.call("admin/admin-batchs/remove", {id:this.selectedItem.id }).then(ret => {
            ret.throwErrors()
            this.closeEditionModal();
            return this.getItems();
          })           
          .catch(err => this.showError(err))  
        }
      })
    }

    private showError(err:any){
        this.$bvToast.toast( $$error(err), {
          title: $$t('error'),
          toaster: 'b-toaster-bottom-full',
          noAutoHide: true,
          variant:'danger'
        })
    }

    private formatDate(value:string):string{
      const m = moment(value,'YYYY-MM-DD"T"HH:mm:ss.SZ')
      return m.format("YYYY-MM-DD") //m.format("LL") + " ("+m.fromNow()+")"
    }

    private archive():Promise<void>
    {
        var ids = this.selectedItems().map(o=>o.id)  
        return api.call("admin/admin-batchs/archive", {ids:ids}).then(() => {
            this.getItems()
        }) 
    }     

    private unarchive():Promise<void>
    {
        var ids = this.selectedItems().map(o=>o.id)  
        return api.call("admin/admin-batchs/unarchive", {ids:ids}).then(() => {
            this.getItems()
        }) 
    }  

    private nbRowsSelected:number = 0
    private get allRowsSelected():boolean { return this.nbRowsSelected > 0 && this.nbRowsSelected == (this.items && this.items.length) }
    private set allRowsSelected(v:boolean){}
    private get anyRowSelected():boolean { return this.nbRowsSelected > 0}

    private select(selected:IBatch[]){
      this.nbRowsSelected = selected.length
    }

    private toggleSelectRow(row:any){
      if(!row.rowSelected)
        row.unselectRow()
      else    
        row.selectRow()  
    }

    private toggleSelectAllRows(headRow:any){
      if(this.allRowsSelected)
        headRow.clearSelected()
      else
        headRow.selectAllRows()
    }

    private selectedItems():IBatch[]
    {
      return this.itemsTable.selectedRows.map((sel:boolean,i:number)=> sel ? this.itemsTable.sortedItems[i] : null).filter((s:IBatch)=>s)
    }    
}

