


























































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import moment  from 'moment';
import { api } from '../../lib/api'
import { IOrder, IOrderItem, IProduct, IClient, IBatch, defaultProduct} from '../../models/schemas' 
import {  } from '../../lib/basket'
import { $$t} from '../../lib/i18n' 
import LvlOrderDetail from '..//lvl-order-detail.vue'
import { Dictionary, RawLocation } from 'vue-router/types/router';

interface IOrderedProduct 
{
	product: IProduct
	quantity: number
	price: any
	shipping: any  
  weight:number,
  categoryName:string,  
  remainingQuantity: number
}

interface IRemaining
{
  remainingQuantity: number
}

@Component({
   components:{LvlOrderDetail}
})
export default class LvlOrderAdmin extends Vue {

    @Prop({default:""})
    public status:String|undefined

    @Prop({default:false})
    private withstock:Boolean|undefined

    public get isStockOk():Boolean{
      if(!this.withstock || this.products.length == 0)
        return true
      return this.products.every(p=> p.quantity <= p.remainingQuantity)
    }

    private ordersFields:any[] = [
      {key:'orderid', label:'ID', sortable:true},
      {key:'date', label: $$t('date'), sortable:true, formatter : this.formatDate},
      {key:'price', label: $$t('order_price')+" ("+$$t('unit_currency')+")", sortable:true},
      {key:'actions', label:'', tdClass:'action-col'},
    ]
    
    private orders:Array<IOrder> = []
    private clients:Array<IClient> = []
    private shippingAreas:Array<any> = []

    private displayClient:string = ""
    private displayArea:string = ""
    private displayOrder:string = ""

    private products:Array<IOrderedProduct> = []
    private productsTotal:IOrderedProduct = {product:defaultProduct(), categoryName:"", quantity:0, price:0, shipping:0, weight : 0, remainingQuantity:0}

    private productsFields:any[] = this.getProductFields()

    private getProductFields():any[]{
      const stockTdClassFunc = (v:number,k:string, i:IOrderedProduct)=> this.withstock && i.remainingQuantity<i.quantity ? "text-danger":"text-success"
      
      const ret = []
      ret.push({key:'productName', label: $$t('product'),  formatter:(v:any, key:string, item:IOrderedProduct)=>item.product.label, sortable:true, sortByFormatted:true })
      if(this.withstock)
        ret.push({key:'remainingQuantity', label: $$t('admin_stock'), sortable:true })
      ret.push({key:'quantity', label: $$t('order_row_quantity'), sortable:true, tdClass: stockTdClassFunc })
      ret.push({key:'weight', label: $$t('weight') +" ("+$$t('unit_kilograms')+")", sortable:true })
      ret.push({key:'price', label: $$t('order_price')+" ("+$$t('unit_currency')+")", sortable:true })
      return ret
    }

    private busy:boolean = false

    private nbRowsSelected:number = 0
    public get allRowsSelected():boolean { return this.nbRowsSelected > 0 && this.nbRowsSelected == (this.orders && this.orders.length) }
    public set allRowsSelected(v:boolean){}
    public get anyRowSelected():boolean { return this.nbRowsSelected > 0}

    private get ordersTable():BTable { return (this.$refs.tblOrders as BTable)}

    private created() {    
      this.displayOrder = this.$route.query.order && this.$route.query.order.toString()   
      Promise.all([
        this.getClients(),
        this.getBatchs(),
        this.getShippingAreas(),
        this.getAllProducts(),
        this.getOrders()
      ])
      .then(()=> this.updateProducts())
    }

    
    private getOrders():Promise<void> {  
      this.busy = true  

      return api.call("admin/admin-orders/list", {status: this.status, client: this.displayClient, shippingArea: this.displayArea, order:this.displayOrder, isArchived:"no" }).then(res => {
        this.busy = false
        this.orders = res.getItems() as IOrder[]        
        this.nbRowsSelected = this.selectedRows().filter(s=>s).length
        this.emitSelectionChanged()
      })   
    }

    @Watch('$route')
    private refreshRoute():Promise<void> {  
      this.displayOrder = this.$route.query.order && this.$route.query.order.toString()      
      return this.getOrders();
    }

    public refresh():Promise<void> {  
      return Promise.all([
        this.getBatchs(),
        this.getOrders()
      ])
      .then(()=> this.updateProducts())
    }

    private allProductsDic:Dictionary<IProduct> = {}
    private getAllProducts():Promise<void>{  

      return api.call("admin/admin-orders/products").then(res => {
        var prods = res.data.items as IProduct[]
        prods.forEach(p=>this.allProductsDic[p.id] = p)
      })   
    }

    private remainingDic:Dictionary<IRemaining> = {}
    private getBatchs():Promise<void>{  
      if(this.withstock)
      {
        return api.call("admin/admin-orders/batchs").then(res => {
          const remainingDic:Dictionary<IRemaining> = {}
          const batchs = res.data.items as IBatch[]
          batchs.forEach(batch=>{
            const productId = batch.product.id
            const remaining = remainingDic[productId]
            if(remaining)
              remaining.remainingQuantity = remaining.remainingQuantity + batch.remainingQuantity
            else
              remainingDic[productId] = { remainingQuantity: batch.remainingQuantity}
          })
          this.remainingDic = remainingDic
        }) 
      }
      else
        return Promise.resolve()
  
    }

    private getClients():void {  
      api.call("admin/admin-orders/clients").then(res => {
        this.clients = res.getItems() as IClient[]
      })   
    }

    private getShippingAreas():Promise<void> {  
      return api.call("admin/admin-orders/shippingAreas").then(res => {
        this.shippingAreas = res.data
      })   
    }

    private formatShippingArea(code:string):string{
      if(this.shippingAreas)
      {
        var area = this.shippingAreas.find(a=>a.value ==code)
        if(area)
          return area.label
      }
      return code
    }

    private changeShippingArea():Promise<void> {  

      return this.getOrders().then(()=>this.updateProducts())
    }

    private get displayOrderLabel():string{
      if(this.orders && this.orders.length==1 && this.orders[0].orderid)
        return this.orders[0].orderid.toString()
      return "";
    }

    private resetDisplayOrder():void{
      var route = Object.assign({},this.$route ) 
      route.query = Object.assign({},this.$route.query ) 
      delete route.query.order
      this.$router.push(route as RawLocation)
    }

    private formatDate(value:string):string{
      const m = moment(value,'YYYY-MM-DD"T"HH:mm:ss.SZ')
      return m.format("YYYY-MM-DD") 
    }

    private orderSelected(selected:IOrder[]){
      this.nbRowsSelected = selected.length
      this.updateProducts()
      this.emitSelectionChanged()
    }

    private toggleSelectRow(row:any){
      if(!row.rowSelected)
        row.unselectRow()
      else    
        row.selectRow()  
      this.updateProducts()
      this.emitSelectionChanged()
    }

    private toggleSelectAllRows(headRow:any){
      if(this.allRowsSelected)
        headRow.clearSelected()
      else
        headRow.selectAllRows()
    }

    private emitSelectionChanged(){
        this.$emit('order-selected')
    }

    private selectedRows():boolean[]
    {
      return this.ordersTable.selectedRows;
    }

    private selectedItems():IOrder[]
    {
      return this.selectedRows().map((sel,i)=> sel ? this.ordersTable.sortedItems[i] : null).filter(s=>s)
    }

    private updateProducts():void{
      var orders:IOrder[] = this.orders
        
      if(this.anyRowSelected && !this.allRowsSelected)
        orders = this.selectedItems()  
          
      if(this.orders.length > 0)
      {
        var orderItems:IOrderItem[][] = orders
          .map(o=>(o.orderitems || []) as IOrderItem[])
          .filter(o=>o)

        if(orderItems.length > 0)
        {
          var orderItemsAggr:IOrderItem[] = orderItems
            .reduce((arr1:IOrderItem[], arr2:IOrderItem[]) => {
              var ret = arr1.map(oi=>Object.assign({},oi))
              arr2.forEach(oi2=>{
                var oi = ret.find(oi=>oi.product.id == oi2.product.id)
                if(!oi)
                  ret.push(Object.assign({},oi2))
                else
                {
                  oi.quantity = oi.quantity + oi2.quantity
                  oi.price = 1 * oi.price + 1 * oi2.price
                }
              })        
              return ret
            }) 

          var orderProducts = orderItemsAggr.map(oi=>{
            var p =  this.allProductsDic[oi.product.id] || oi.product
            var remaining = this.remainingDic[p.id]
            return Object.assign({
              product : p,
              categoryName: p.productCategory && p.productCategory.label,
              weight : (p.weight || 0)* oi.quantity / 1000,
              remainingQuantity : remaining && remaining.remainingQuantity || 0
            },oi) as IOrderedProduct             
          })
          this.products = orderProducts
        }
        else
          this.products = []
      }
      else
        this.products = []


      var totalPrice = 0
      var totalQuantity = 0
      var totalWeight = 0
      var totalRemaining = 0
      this.products.forEach(p=>{
        totalPrice+=(1 * p.price)
        totalQuantity+=p.quantity
        totalWeight+=p.weight
        totalRemaining+=p.remainingQuantity
      })
      this.productsTotal.price = totalPrice
      this.productsTotal.quantity = totalQuantity
      this.productsTotal.weight = totalWeight
      this.productsTotal.remainingQuantity = totalRemaining
    }

    changeSelectedOrdersStatus(targetStatus:string):Promise<void>
    {
        var ids = this.selectedItems().map(o=>o.id)  
        return api.call("admin/admin-orders/changeStatus", {ids:ids, status: targetStatus }).then(() => {
            this.refresh()
        }) 
    }


    archiveSelectedOrders():Promise<void>
    {
        var ids = this.selectedItems().map(o=>o.id)  
        return api.call("admin/admin-orders/archive", {ids:ids}).then(() => {
            this.refresh()
        }) 
    }    
       
}

