








































































































































import { Component, Vue } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import { api } from '../../lib/api'
import { validation } from '../../lib/validation'
import { IProductCategory, defaultProductCategory} from '../../models/schemas' 
import { $$t, $$error } from '../../lib/i18n' 

@Component
export default class AdminProductCategoriesPage extends Vue {

    private fields:any[] = [
      {key:'name', label:$$t('admin_name'), sortable:true},
      {key:'products', label: $$t('products'), sortable:true},
      {key:'isActive', label: $$t('admin_isvisible'), sortable:true},
      {key:'sortIndex', label:$$t('admin_sortindex'), sortable:true}
    ]

    private validation = validation()

    private locales:Array<any> = []
    private items:Array<IProductCategory> = []
    private displayActive:string = ""
    private busy:boolean = false
    private formOpened:boolean = false

    private selectedItem:IProductCategory = defaultProductCategory()
    private get selectedItemImage():string|undefined {
      const image = this.selectedItem && this.selectedItem.image
      if(image)
      {
        if(image.startsWith("data:image/"))
            return image;
        return '/upload/' + image;     
      }
      return undefined
    }

    private selectedItemRemovable():boolean {
      return this.selectedItem && this.selectedItem.id!="" && !this.selectedItem.isActive && (!this.selectedItem.products || this.selectedItem.products.length == 0)
    }

    private uploadedFile:File|undefined

    private get itemsTable():BTable { return (this.$refs.tblItems as BTable)}

    mounted() {
      Promise.all([
        this.getLocales()
      ])
      .then(()=> this.getItems())  
    }

    private getLocales():Promise<void> {  
      return api.call("admin/admin-product-categories/locales").then(res => {
        this.locales = res.data
      })   
    }

    private getItems():Promise<void> {  
      this.busy = true  

      return api.call("admin/admin-product-categories/list", {isActive:this.displayActive }).then(res => {
        this.busy = false
        this.items = res.getItems() as IProductCategory[]
      })   
    }

    private select(item:IProductCategory)
    {
      this.validation.reset()
      this.selectedItem = item
      this.formOpened = true
    }

    private createNew()
    {
      const created = defaultProductCategory();
      created.sortIndex = this.items ? this.items.length+1 : 1
      this.select(created)
    }
    
    private closeEditionModal()
    {
      this.validation.reset()
      this.itemsTable.clearSelected()
      this.formOpened = false
    }

    private uploadFile(file:File)
    {
      if(this.selectedItem && file)
      {
        var reader  = new FileReader();
        reader.addEventListener("load", ()=>{
          this.selectedItem.image = reader.result as string
        })
        reader.readAsDataURL(file)       
      }     
    }

    private save()
    {
      if(this.validation.ko)
        return;
      this.busy = true  

      api.call("admin/admin-product-categories/update", {item:this.selectedItem }).then(ret => {
        ret.throwErrors()
        this.closeEditionModal();
        return this.getItems();
      }) 
      .catch(err => this.showError(err))     
    }

    private cancel()
    {
      this.getItems();
      this.closeEditionModal();   
    }

    private remove()
    {
      this.$bvModal.msgBoxConfirm(
        $$t("admin_confirm_delete") + " : \""+this.selectedItem.name+"\" ?", 
        {title: $$t("admin_delete"), headerBgVariant:"danger", headerTextVariant:"light", centered:true}
        )
      .then(ok=>{
        if(ok){
          this.busy = true  

          api.call("admin/admin-product-categories/remove", {id:this.selectedItem.id }).then(ret => {
            ret.throwErrors()
            this.closeEditionModal();
            return this.getItems();
          })           
          .catch(err => this.showError(err))  
        }
      })
    }
    
    private showError(err:any){
        this.$bvToast.toast($$error(err), {
          title: $$t('error'),
          toaster: 'b-toaster-bottom-full',
          noAutoHide: true,
          variant:'danger'
        })
    }
}

